require('./bootstrap');

$('a.once').on('click', function(){
    $(this).attr('disabled', true)
        .addClass('disabled');
});

$('a.ajax-action').on('click', function(e){
    e.preventDefault();

    let el = $(this)
    el.attr('disabled', true)
        .addClass('disabled');

    $.ajax({
        url: el.attr('href'),
        success: function () {
            el.removeAttr('disabled')
                .removeClass('disabled');
        },
        error: function (xhr) {
            el.removeAttr('disabled')
                .removeClass('disabled');
            var errorMessage = JSON.parse(xhr.responseText).message;
            alert(errorMessage);
        }
    });
});

$('#form-attendance').on('submit', function(){
    $(this).find('[type=submit]')
        .attr('disabled', true)
        .addClass('disabled');
});